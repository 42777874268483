<template>
  <div class="login-view">
    <div class="layout login-view__content login-view__content--bg">
      <div class="login-view__content--left">
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="login">
          <div class="content-title">{{ $t("loginPage.signInToYourAccount") }}</div>
          <v-text-field
            v-model="email"
            type="email"
            :label="$t('loginPage.typeYourEmail')"
            background-color="white"
            filled
            light
            autofocus
          />
          <v-text-field
            v-model="password"
            type="password"
            :label="$t('loginPage.typeYourPassword')"
            background-color="white"
            filled
            light
          />
          <v-btn :loading="loading" class="sign-in-btn" type="submit" dark text x-large>{{ $t('loginPage.signIn') }}</v-btn>
          <v-btn class="d-md-none" :to="{ name: 'Onboarding' }" dark text x-large>{{ $t('loginPage.createAccount') }}</v-btn>

          <v-dialog v-model="forgotPasswordModal" max-width="600px" @keydown.esc="forgotPasswordModal = false">
            <template #activator="{ on, attrs }">
              <v-btn dark text x-large v-bind="attrs" v-on="on">{{ $t('loginPage.forgotMyPassword') }}</v-btn>
            </template>
            <!-- XXX v-if is there to prevent the modal from keeping state, but it breaks the fade out animation. -->
            <forgot-password v-if="forgotPasswordModal" :email="email" @close="forgotPasswordModal = false"/>
          </v-dialog>
        </v-form>
      </div>
      <img
        class="login-view__content--right"
        src="../../../assets/singin.jpg"
        width="460"
        height="auto"
      >
    </div>

    <div class="layout login-view__content flex">
      <div class="login-view__content--left">
        <p>&nbsp;</p>
        <p>&nbsp;</p>
        <p>&nbsp;</p>
      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import ForgotPassword from '../components/ForgotPassword.vue'

import Vue from 'vue'

export default Vue.extend({
  name: "Login",
  components: {
    ForgotPassword,
  },
  data() {
    return {
      valid: true,
      forgotPasswordModal: false,
      email: "",
      password: "",
      loading: false,
    }
  },
  methods: {
    login() {
      this.loading = true
      this.$auth
        .login({
          data: { email: this.email, password: this.password },
        })
        .then((user) => {
          this.loading = false
          this.$i18n.locale = user.data.language
          localStorage.setItem('locale', user.data.language)
        })
        .catch(()=>{
          this.loading = false
          this.password = ""
        })
    },
  },
})
</script>

<style lang="scss">
.sign-in-btn {
  border: 2px solid white !important;
}

.login-view {
  background-color: #0f1015;
  padding-top: 102px;

  .layout {
    max-width: 1040px;
    width: 100%;
    margin: 0 auto;
  }

  &__content {
    position: relative;
    padding: 40px 80px;

    &--bg {
      background-color: #1d1f24;
      padding: 63px 80px;
    }

    &--left {
      max-width: 412px;
    }

    &--right {
      height: auto;
      position: absolute;
      right: 35px;
      top: -35px;
    }

    .content-title {
      text-transform: uppercase;
      font-size: 32px;
      color: #fff;
      letter-spacing: 4px;
      line-height: 56px;
      margin-bottom: 80px;
    }
  }

  .footer {
    background-color: #fff;
    padding: 120px 0 52px;

    &__content {
      padding-top: 52px;

      &--column {
        line-height: 32px;

        *,
        a {
          display: block;
          color: #0f1015;
        }
      }
    }

    .icon {
      display: inline-block;
      margin-right: 8px;
      border-radius: 50%;
      height: 32px;
      width: 32px;

      &--facebook {
        background-color: #3b5998;
      }

      &--linkedin {
        background-color: #007bb6;
      }
    }
  }
}

.device-mobile {
  .header .layout {
    height: 56px;

    .monestro-logo {
      margin-right: 10px;

      img {
        height: 18px;
        width: auto;
      }
    }

    .header__right-side {
      a {
        margin: 0;
        padding: 5px;
        font-size: 14px;
        text-align: center;
        border: 1px solid #13151a;

        + a {
          margin-left: 5px;
        }
      }
    }
  }

  .login-view {
    padding: 10px;

    &__content {
      padding: 15px 10px;

      &--left {
        max-width: 100%;
      }

      &--right {
        display: none;
      }

      .content-title {
        font-size: 18px;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom: 15px;
      }
    }

    .footer {
      padding: 20px 10px;

      &__content {
        padding-top: 20px;

        &--column {
          margin-bottom: 15px;
        }
      }
    }
  }
}
</style>
